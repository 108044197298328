var site = site || {};
var Unison = Unison || {};

Drupal.behaviors.signinWidgetV1 = (function ($) {
  var bps = Unison.fetch.all();
  var bp = Unison.fetch.now();
  var isMobile = parseInt(bp.width, 10) < parseInt(bps.landscape, 10);
  var $document = $(document);
  var $profileImage;
  var behavior = {
    attached: false,
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;
      var $siteHeader = $('.js-site-header--v1', context);
      var $template = $('.js-signin-widget-v1', context);
      var $fullSizeTemplate = $('.js-elc-full-size-sample-v1', context);
      var $globalOverlay = $('.js-content-dim', context);
      var $siteHeaderDesktop = $('.site-header__utility-desktop', $siteHeader);
      var $signInContainer = $template.closest('.js-nav-content-dropdown');
      var $signInWidgetContainer = $template.closest('.js-nav-content-block[data-content="signin"]');
      var $signInTrigger = $('.js-nav-content', $siteHeaderDesktop).filter('[data-content="signin"]');
      var $closeIcon = $('.js-signin-widget-close', $template);
      var showWidgetOnPageLoadDesktop = $template.data('showToutOnLoadDesktop');
      var signedIn = Number(site.userInfoCookie.getValue('signed_in')) === 1 || Number(site.userInfoCookie.getValue('csr_logged_in')) === 1;

      $profileImage = $('.js-signin-widget-profile-image', $template);
      // full size samples needs to be populated only for signed in users
      if (!signedIn) {
        return false;
      }

      if (!isMobile) {
        $template.removeClass('hidden');
      }

      /**
       * Update day
       * Get the list of days defined in CMS and replace ::TODAY::
       * string. For e.g., Happy Friday!
       *
       * @returns {boolean} false
       */
      var updateDay = function () {
        var $wishes = $('.js-signin-widget-wishes', $template);
        var $days = $template.data('days');

        $wishes.html($wishes.html().replace('::TODAY::', $days.split(',')[new Date().getDay()]));
      };

      // Update current day in the tout
      updateDay();

      // Close widget
      $closeIcon.once().on('click', function () {
        $signInWidgetContainer.addClass('hidden');
        $siteHeader.trigger('gnav.close');
        $globalOverlay.trigger('overlay.closed');
      });

      // Whenever overlay is closed, hide the signin widget
      $globalOverlay.on('overlay.closed', function () {
        $signInContainer.removeClass('site-header__content-signin-widget');
        $template.siblings('.content').removeClass('hidden');
        $signInWidgetContainer.addClass('hidden');
        $signInTrigger.removeClass('active');
      });

      // Suppress existing click action on gnav signed in link
      $document.on('gnav.events.attached', function () {
        $signInTrigger
          .off('click')
          .on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();
          });
        if (!isMobile) {
          if (showWidgetOnPageLoadDesktop && !$.cookie('signin-widget-displayed')) {
            $signInTrigger.trigger('mouseenter');
            $.cookie('signin-widget-displayed', 1, {
              expires: 1,
              path: '/'
            });
          }
        }
      });

      // Signin Widget should be displayed in mouse hover action on
      // signed in user name
      $signInTrigger
        .data('hover', true)
        .on('mouseenter', function () {
          var windowWidth = $(window).width();
          var maxWindowWidth = 1200;
          var cssRightValue = 0;

          if (!$signInWidgetContainer.hasClass('hidden')) {
            return false;
          }
          $signInContainer.addClass('site-header__content-signin-widget');
          $(this).trigger('mouseenter.content-trigger');
          $template.siblings('.content').addClass('hidden');
          $document.trigger('signin-widget-displayed');
          /**
           * When we increase the size of the screen (or) view in big screen,
           * The 'right' css property set to 0 causes UI issues
           * So, the curent screen width is used to calculate the 'right' value and it is set here
           */
          if (windowWidth > maxWindowWidth) {
            cssRightValue = (windowWidth - maxWindowWidth) / 2;
            $signInContainer.css({
              right: cssRightValue + 'px'
            });
          }
        });

      // Responsiveness for mobile
      Unison.on('change', function (bp) {
        isMobile = parseInt(bp.width, 10) < parseInt(bps.landscape, 10);
        if (isMobile) {
          $template.addClass('hidden');
        } else {
          $template.removeClass('hidden');
        }
      });

      $document.on('signin-widget-displayed', function () {
        $fullSizeTemplate.each(function () {
          var $this = $(this);
          var $thisCarousel;
          var $activeProductIdSlide;
          var prodId;

          if ($this.is(':visible')) {
            $thisCarousel = $('.js-full-size-samples-carousel', $this);
            if ($thisCarousel.attr('data-slick-refreshed') !== '1') {
              $thisCarousel
                .attr('data-slick-refreshed', '1')
                .slick('refresh');
            }
            $activeProductIdSlide = $thisCarousel.find('.slick-slide.slick-active');
            prodId = $activeProductIdSlide.attr('data-product-id');
            if (prodId) {
              // Fire analytics tracking, when widget is expanded
              $document.trigger('full-size-widget-viewed', {
                'prodId': prodId
              });
            }
          }
        });
      });
    }
  };

  // Update user profile image
  $document.on('user.loaded', function (e, user) {
    if (user && user.profile_pic_thumbnail) {
      $profileImage
        .addClass('signin-widget__profile-img--updated')
        .attr('src', user.profile_pic_thumbnail);
    }
  });

  return behavior;
})(jQuery);
